.custom-swal-button {
  border: none;
  border-radius: 4px;
  padding: 8px 16px; /* Ajusta el padding para aumentar el tamaño */
  font-size: 14px; /* Tamaño de la fuente */
  font-weight: 500; /* Peso de la fuente */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Efecto de transición */
  margin: 0 4px; /* Espaciado entre botones */
}

.custom-swal-button.mui-button--success {
  background-color: #2e7d32; /* Color de éxito de Material-UI */
  color: white; /* Color del texto */
}

.custom-swal-button.mui-button--error {
  background-color: #f44336; /* Color de error de Material-UI */
  color: white; /* Color del texto */
}

/* Hover effects */
.custom-swal-button.mui-button--success:hover {
  background-color: #1b5e20; /* Color de éxito más oscuro */
}

.custom-swal-button.mui-button--error:hover {
  background-color: #c62828; /* Color de error más oscuro */
}

.custom-swal-button:focus {
  outline: none; /* Eliminar el contorno por defecto */
}