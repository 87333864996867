.table-header {
    background-color: #636569 !important;
    color: #fff !important;
    font-weight: bold !important;
}

.icon-color {
    color: #8c92bc !important;
}

.button-save {
    background-color: #8c92bc !important;
    color: white !important;
    text-transform: none !important;
}

.button-save:hover {
    background-color: #7a7f9e !important;
}

.button-close {
    background-color: #636569 !important;
    color: white !important;
    text-transform: none !important;
}

.button-close:hover {
    background-color: #5a5d5f !important;
}

.button-validation {
    background-color: #C4F45D !important;
    color: #636569 !important;
    font-weight: 700 !important;
}

* {
    font-family: 'Montserrat', sans-serif !important;
}
.tab-lowercase {
    text-transform: none !important;
}
/* index.css */
tbody tr:hover {
  background-color: rgba(99, 101, 105, 0.416) !important;
  cursor: pointer !important;
}

.box-sombra {
    border-radius: 5px; 
    box-shadow: 0px 0px 12px 0px hsl(0deg 0% 0% / 18%);
    padding: 15px;
    margin-bottom: 10px;
    background-color: white; 
  }

.title1 {
    font-size: 20px;
    margin: 5px; 
    margin-left: 30px;
    color: rgba(92, 86, 86, 0.861);
}
  
.title2 {
    font-size: 20px;
    margin: 5px; 
    margin-left: 10px;
    color: rgba(92, 86, 86, 0.861);
}

.static-buttons {
    position: sticky;
    top: 64px;
    background-color: white;
    z-index: 2;
    padding-top: 10px;
}
  